<svelte:options immutable={true} />

<script>
  import { onMount, createEventDispatcher } from "svelte";
  import { pannable } from "../../utils/pannable.js";
  import { Fonts } from "../../utils/prepareAssets.js";
  import { tapout } from "../../utils/tapout.js";
  import Toolbar from "../../components/Toolbar.svelte";
  import { _ } from "../../services/i18n";
  import Visibility from "../../assets/svg/visibility.svelte";
  import LivePrint from "../../assets/svg/LivePrint.svelte";
  import AddOptions from "../../components/AddOptions/AddOptions.svelte";
  export let width;
  export let height;
  export let x;
  export let id;
  export const text = "text";
  export let y;
  export let pageScale = 1;
  export let lineHeight;
  export let size;
  export let fontFamily;
  export let editMode;
  export let charactersNumber;
  export let typeSelected;
  export let visibility;
  export let livePrint;
  export let relation;
  export let color;
  export let details;
  export let position;
  export let selectedPageIndex;
  export let allObjects;
  export let pIndex;
  export let pdfpageHeight;
  export let lastModification;
  export let scroll;
  let mousex = 0;
  let mousey = 0;
  let yy = 0;
  let checktypes = ["X", "✓"];
  let dateFormats = ["YYYY-MM-DD", "DD-MM-YYYY", "YYYY/MM/DD", "DD/MM/YYYY"];
  let LivePrintTypes = ["Date", "Check", "BIC", "IBAN", "Text"];
  const Families = Object.keys(Fonts);
  let variants = Fonts[fontFamily] ? Fonts[fontFamily].variants : ["regular"];
  export let _currentVariant = variants[0];
  const Types = [
    "Text",
    "Check",
    "Email",
    "Numero",
    "BIC",
    "IBAN",
    "Image",
    "HTML",
    "Date",
  ];
  let TextFormats = [
    "Basique",
    "Adaptation Taille Police",
    "Retour a la ligne automatique",
    "Selection",
  ];
  export let TextFormat = "Basique";
  let langues = ["latin", "arabic"];
  export let langue = "latin";
  let _fontFamily = fontFamily;
  const numFormats = ["Default", "Mobile"];
  let numFormat = "Default";
  const dispatch = createEventDispatcher();
  let startX;
  let startY;
  let canvas;
  let operation = "";
  let directions = [];
  let _size = size;
  let _lineHeight = lineHeight;
  let editable;
  let dx = 0;
  let dy = 0;
  let dw = 0;
  let dh = 0;
  let highlight = false;
  let selected = false;
  let copyimage;
  let hex = "#000000";
  let selectedObject;
  let scrollPosition = 0;
  export let options = [];
  export let checkFormat = "X";
  export let dateFormat = "DD-MM-YYYY";
  export let line;
  async function render() {
    editable.innerHTML = line;

    dispatch("update", {
      width: width * pageScale,
      height: height * pageScale,
      lines: extractLines(),
    });
  }

  function handlePanMove(event) {
    /* onChangePage(yy); */

    const _dx = (event.detail.x - startX) / pageScale;
    const _dy = (event.detail.y - startY) / pageScale;

    if (operation === "move") {
      dx = _dx;
      dy = _dy;

      //  console.log("move",dy)
    } else if (operation === "scale") {
      if (directions.includes("left")) {
        dx = _dx;
        dw = -_dx;
      }

      if (directions.includes("top")) {
        dy = _dy;
        dh = -_dy;
      }
      if (directions.includes("right")) {
        dw = _dx;
      }
      if (directions.includes("bottom")) {
        dh = _dy;
      }
    }
  }
  function sanitize() {
    let weirdNode;
    while (
      (weirdNode = Array.from(editable.childNodes).find(
        (node) => !["#text", "BR"].includes(node.nodeName)
      ))
    ) {
      editable.removeChild(weirdNode);
    }
  }
  function onFocusOut() {
    operation = "";
  }

  function colorCallback(rgba) {
    dispatch("update", {
      color: rgba,
    });
  }

  function decToHex(value) {
    if (value > 255) {
      return "FF";
    } else if (value < 0) {
      return "00";
    } else {
      return value.toString(16).padStart(2, "0").toUpperCase();
    }
  }
  function rgbToHex(r, g, b) {
    return "#" + decToHex(r) + decToHex(g) + decToHex(b);
  }

  function handlePanEnd(event) {
    if (dx === 0 && dy === 0) {
      return editable.focus();
    }
    if (operation === "move") {
      // console.log("fvgb")
      dispatch("update", {
        x: x + dx,
        y: y + dy,
      });
      dx = 0;
      dy = 0;
      // scrollPosition=0
    } else if (operation === "scale") {
      dispatch("update", {
        x: x + dx,
        y: y + dy,
        width: width + dw,
        height: height + dh,
      });
      dx = 0;
      dy = 0;
      dw = 0;
      dh = 0;
      directions = [];
    }

    // else if (operation === "scroll") {
    //       dispatch("update", {
    //         x: x + dx,
    //         y:y+dy,
    //         width: width + dw,
    //         height: height + dh,
    //       });
    //       dx = 0;
    //       dy = 0;
    //       dw = 0;
    //       dh = 0;
    //       directions = [];
    //     }

    operation = "";
    if (editMode == "EDIT") dispatch("mode", "MOVE");
    window.removeEventListener("keydown", onKeyDownTool, false);
    window.removeEventListener("mousemove", onMouseMoveTool, false);
  }

  export function onFocusTool() {
    operation = "tool";
  }

  function onCopyTool(ev) {
    let xx = x + mousex / pageScale >= 0 ? x + mousex / pageScale : 0;
    let yy = y + mousey / pageScale >= 0 ? y + mousey / pageScale : 0;
    // function to check the detection
    ev = ev || window.event; // Event object 'ev'
    var key = ev.which || ev.keyCode; // Detecting keyCode

    // Detecting Ctrl
    var ctrl = ev.ctrlKey ? ev.ctrlKey : key === 17 ? true : false;

    // If key pressed is V and if ctrl is true.
    if (key == 86 && ctrl && xx > 0 && editMode == "MOVE") {
      // print in console.
      let timestampId = Date.now();
      dispatch("copy", {
        ...copyimage,
        id: timestampId,
        x: xx,
        y: yy,
      });
    }
  }
  function handlePanStart(event) {
    copyimage = {
      type: "image",
      width: width / pageScale,
      height: height / pageScale,
      size: _size,
      lineHeight: _lineHeight,
      fieldType: typeSelected,
      charactersNumber: charactersNumber,
      line: line,
      visibility: visibility,
      id: id,
    };
    details = copyimage;
    position = event;
    selected = true;
    if (editMode == "MOVE" && selected) {
      window.addEventListener("keydown", onKeyDownTool, false);
      window.addEventListener("mousemove", onMouseMoveTool, false);
    }
    startX = event.detail.x;
    startY = event.detail.y;
    if (event.detail.target === event.currentTarget) {
      return (operation = "move");
    }

    operation = "scale";
    directions = event.detail.target.dataset.direction;
  }
  function onDelete() {
    window.removeEventListener("keydown", onKeyDownTool, false);
    window.removeEventListener("mousemove", onMouseMoveTool, false);
    dispatch("delete");
  }
  function onChangeFont() {
    variants = Fonts[_fontFamily].variants;
    if (Fonts[_fontFamily].files) {
      dispatch("update", {
        fontFamily: _fontFamily,
        fontUrl: Fonts[_fontFamily].files[_currentVariant],
        variant: _currentVariant,
      });
    } else {
      dispatch("update", {
        fontFamily: _fontFamily,
        variant: _currentVariant,
      });
    }
  }
  function onChangeVariant() {
    dispatch("update", {
      fontUrl: Fonts[_fontFamily].files[_currentVariant],
      variant: _currentVariant,
    });
  }
  function onChangeSize() {
    dispatch("update", {
      size: _size,
    });
  }
  function onChangeLineHeight() {
    dispatch("update", {
      name: _lineHeight,
    });
  }
  function onChangeType() {
    if (typeSelected === "Text") {
      dispatch("update", {
        fieldType: typeSelected,
        numFormat: numFormat,
        TextFormat: TextFormat,
        langue: langue,
        FormatCode: 0,
      });
    } else {
      dispatch("update", {
        fieldType: typeSelected,
        numFormat: numFormat,
      });
    }
  }
  function onChangeNumFormat() {
    dispatch("update", {
      numFormat: numFormat,
    });
  }

  function onChangeVisibility() {
    dispatch("update", {
      visibility: visibility,
    });
  }

  function onChangeLivePrint() {
    dispatch("update", {
      livePrint: livePrint,
    });
  }

  function updateText(e) {
    editable.innerHTML = e.target.value;
    dispatch("update", {
      lines: extractLines(),
    });
  }

  function updateRelation(e) {
    dispatch("update", {
      relation: e.target.value,
    });
  }

  function onChangeCharactersNumber() {
    dispatch("update", {
      charactersNumber: charactersNumber,
    });
  }
  function moveToPage(event) {
    event.preventDefault();
    let val = parseInt(event.target.value);
    if (0 < val && val <= allObjects.length) {
      dispatch("transfer", {
        page: event.target.value - 1,
        id: id,
        currentpage: selectedPageIndex,
        width: width / pageScale,
        height: height / pageScale,
      });
    }
  }
  function onKeydown(e) {
    if (editMode == "EDIT") {
      const childNodes = Array.from(editable.childNodes);
      if (e.keyCode === 13) {
        // prevent default adding div behavior
        e.preventDefault();
        const selection = window.getSelection();
        const focusNode = selection.focusNode;
        const focusOffset = selection.focusOffset;
        // the caret is at an empty line
        if (focusNode === editable) {
          editable.insertBefore(
            document.createElement("br"),
            childNodes[focusOffset]
          );
        } else if (focusNode instanceof HTMLBRElement) {
          editable.insertBefore(document.createElement("br"), focusNode);
        }
        // the caret is at a text line but not end
        else if (focusNode.textContent.length !== focusOffset) {
          document.execCommand("insertHTML", false, "<br>");
          // the carat is at the end of a text line
        } else {
          let br = focusNode.nextSibling;
          if (br) {
            editable.insertBefore(document.createElement("br"), br);
          } else {
            br = editable.appendChild(document.createElement("br"));
            br = editable.appendChild(document.createElement("br"));
          }
          // set selection to new line
          selection.collapse(br, 0);
        }
      }

      dispatch("update", {
        lines: extractLines(),
      });
    }
  }

  async function onPaste(e) {
    // get text only
    const pastedText = e.clipboardData.getData("text");
    document.execCommand("insertHTML", false, pastedText);
    // await tick() is not enough
    await timeout();
    sanitize();
  }
  function extractLines() {
    const nodes = editable.childNodes;
    let lineText = "";
    for (let index = 0; index < nodes.length; index++) {
      const node = nodes[index];
      lineText += node.textContent;
    }
    return lineText;
  }

  function onBlur() {
    if (editMode == "EDIT" && highlight) {
      if (operation !== "edit" || operation === "tool") return;
      editable.blur();
      sanitize();

      dispatch("update", {
        lines: extractLines(),
        x: x + dx,
        y: y + dy,
        width: width + dw,
        height: height + dh,
      });
      dx = 0;
      dy = 0;
      dw = 0;
      dh = 0;
      operation = "";
      highlight = false;
    } else if (editMode == "MOVE" && selected) {
      editable.blur();
      sanitize();

      dispatch("update", {
        lines: extractLines(),
        x: x + dx,
        y: y + dy,
        width: width + dw,
        height: height + dh,
      });
      dx = 0;
      dy = 0;
      dw = 0;
      dh = 0;
      operation = "";
      highlight = false;
      selected = false;
    }
    // else if (operation=="scroll") {
    //   editable.blur();
    //   sanitize();

    //   dispatch("update", {
    //     lines: extractLines(),
    //     // x: x + dx,
    //     y:  y,
    //     width: width + dw,
    //     height: height + dh,
    //   });
    //   dx = 0;
    //   dy = 0;
    //   dw = 0;
    //   dh = 0;
    // // scrollPosition=y
    //   operation = "";
    //   highlight = false;
    //   selected = false;
    // }
    window.removeEventListener("keydown", onKeyDownTool, false);
    window.removeEventListener("mousemove", onMouseMoveTool, false);
    window.onscroll = function () {};
  }
  export function onFocus() {
    if (editMode == "EDIT") {
      highlight = true;
      operation = "edit";
    }
  }
  function onChangePage(e) {
    let test = allObjects[pIndex].filter((e) => e.id == details.id);
    if (
      e.movementY > 0 &&
      e.layerY >
        pdfpageHeight * (pIndex == 0 ? pIndex + 2 : pIndex + 1) +
          details.height * 3
    ) {
      test[0].y = 0;
      test[0].x = 0;
      test[0].width = details.width;
      test[0].height = details.height;
      allObjects[pIndex].splice(allObjects[pIndex].indexOf(test), 1);
      allObjects[pIndex + 1].push(...test);
      selectedPageIndex = pIndex + 1;
    }
    if (e.movementY < 0 && pIndex !== 0 && e.layerY < 0) {
      test[0].y = pdfpageHeight - 63 - details.height;
      test[0].x = 0;
      test[0].width = details.width;
      test[0].height = details.height;
      allObjects[pIndex].splice(allObjects[pIndex].indexOf(test), 1);
      allObjects[pIndex - 1].push(...test);
      selectedPageIndex = pIndex - 1;
    }
  }

  function onMouseMoveTool(e) {
    mousex = e.clientX - startX;
    mousey = e.clientY - startY;
    yy = e;
  }

  function onKeyDownTool(e) {
    onCopyTool(e);
    let scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    let scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
    window.onscroll = function () {
      window.scrollTo(scrollLeft, scrollTop);
    };
    switch (e.key) {
      case "ArrowDown":
        dispatch("update", {
          y: y + 1,
        });
        break;
      case "ArrowUp":
        dispatch("update", {
          y: y - 1,
        });
        break;
      case "ArrowRight":
        dispatch("update", {
          x: x + 1,
        });
        break;
      case "ArrowLeft":
        dispatch("update", {
          x: x - 1,
        });
        break;
      default:
        break;
    }
  }
  async function onBlurTool() {
    if (operation !== "tool" || operation === "edit" || selected === false)
      return;
    dispatch("update", {
      lines: extractLines(),
      lineHeight: _lineHeight,
      size: _size,
      fontFamily: _fontFamily,
    });
    let tuto_existance = document.querySelector(".introjs-tooltip")
    if(!tuto_existance){
      operation = "";
      selected = false;
      dispatch("mode", "MOVE");
    }
  }
  onMount(render);
</script>

{#if operation && editMode == "MOVE"}
  <div
    class="absolute select-none"
    style="width:{280 * 2.13}px;transform: translate(0px,
    {y + dy + size - lineHeight * 2}px);"
  >
    <hr style="border-top: 0.1px dashed #888;" />
  </div>

  <div class="ruler_container">
    <ul class="ruler-x">
      <li />
      <li />
      <li />
      <li />
      <li />
      <li />
      <li />
      <li />
      <li />
      <li />
      <li />
      <li />
      <li />
      <li />
      <li />
      <li />
      <li />
      <li />
      <li />
      <li />
      <li />
      <li />
      <li />
      <li />
      <li />
    </ul>
    <ul class="ruler-y">
      <li />
      <li />
      <li />
      <li />
      <li />
      <li />
      <li />
      <li />
      <li />
      <li />
      <li />
      <li />
      <li />
      <li />
      <li />
      <li />
      <li />
      <li />
      <li />
      <li />
      <li />
      <li />
      <li />
      <li />
      <li />
    </ul>
  </div>
{/if}
{#if operation && editMode == "EDIT"}
  <Toolbar>
    <div
      use:tapout
      on:tapout={onBlurTool}
      on:mousedown={onFocusTool}
      on:touchstart={onFocusTool}
    >
      <div class="z-10">
        <div
          class="sidebar fixed top-0 z-1 bottom-0 lg:left-0 p-2 w-[400px] overflow-y-auto"
          style="width:20%; margin-top:8rem;background-color:#624771"
        >
          <div class="idFieldInput mr-2 flex-col items-center">
            <div style="margin: 5px; ">
              <p class="text-white">Id :</p>
              <div style="display:flex;flex-direction:row;">
                <svg
                  class="z-10"
                  width="48"
                  height="46"
                  viewBox="0 0 36 36"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width="40" height="38" rx="5" fill="#F3EAF8" />
                  <path
                    d="M13 16.74V19.94M18 15V21.68M23 16.74V19.94M15 28H21C26 28 28 26 28 21V15C28 10 26 8 21 8H15C10 8 8 10 8 15V21C8 26 10 28 15 28Z"
                    stroke="#945FAF"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <input
                  class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-10 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="text"
                  type="text"
                  style="margin-left :-2.4rem; padding-left:3rem;"
                  bind:value={line}
                  on:change={updateText}
                />
              </div>
            </div>
          </div>
          <!-- <div>
            <h2 class="text-white">Id :</h2>
          </div>
          <div class="flex justify-center items-center m-5">
            <input
              class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              id="text"
              type="text"
              bind:value={line}
              on:change={updateText}
            />
          </div> -->
          <div class="lineHeightInput mr-2 flex-col items-center">
            <div style="margin: 5px; ">
              <p class="text-white">{$_("workspace.hauteurLigne")}</p>
              <div style="display:flex;flex-direction:row;">
                <svg
                  class="z-10"
                  width="48"
                  height="46"
                  viewBox="0 0 36 36"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width="40" height="38" rx="5" fill="#F3EAF8" />
                  <path
                    d="M9 28H27M9 8H27M18 12V24M20.83 13.72L18 10.89L15.17 13.72M20.83 21.89L18 24.72L15.17 21.89"
                    stroke="#945FAF"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>

                <input
                  type="number"
                  min="1"
                  max="10"
                  style="margin-left :-2.4rem; padding-left:3rem;"
                  step="0.1"
                  class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="grid-state"
                  bind:value={_lineHeight}
                  on:change={onChangeLineHeight}
                />
              </div>
            </div>
          </div>
          <div class="typeInput mr-2">
            <div class="flex-col items-center" style="margin: 5px;">
              <p class="text-white">{$_("workspace.typeContenu")}</p>
              <div
                class="relative w-60 md:w-60"
                style="display:flex;flex-direction:row;"
              >
                <svg
                  class="z-10"
                  width="48"
                  height="46"
                  viewBox="0 0 36 36"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width="38" height="36" rx="5" fill="#F3EAF8" />
                  <path
                    d="M13 16.74V19.94M18 15V21.68M23 16.74V19.94M15 28H21C26 28 28 26 28 21V15C28 10 26 8 21 8H15C10 8 8 10 8 15V21C8 26 10 28 15 28Z"
                    stroke="#945FAF"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <select
                  style="margin-left :-2.4rem; padding-left:3rem;"
                  bind:value={typeSelected}
                  on:change={onChangeType}
                  class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="grid-state"
                >
                  {#each Types as type}
                    <option value={type}>{type}</option>
                  {/each}
                </select>
              </div>
            </div>
          </div>
          <div class="fontSizeInput mr-2 flex-col items-center">
            <div style="margin: 5px;">
              <p class="text-white">{$_("workspace.tailleText")}</p>
              <div style="display:flex;flex-direction:row;">
                <svg
                  class="z-10"
                  width="48"
                  height="46"
                  viewBox="0 0 36 36"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width="40" height="38" rx="5" fill="#F3EAF8" />
                  <path
                    d="M26 12.9524V23.0524C25.595 22.9697 25.1758 22.9886 24.78 23.1075C24.3841 23.2265 24.0239 23.4417 23.7316 23.734C23.4393 24.0263 23.2241 24.3865 23.1051 24.7824C22.9862 25.1783 22.9672 25.5975 23.05 26.0024H12.95C13.0328 25.5975 13.0138 25.1783 12.8949 24.7824C12.7759 24.3865 12.5607 24.0263 12.2684 23.734C11.9761 23.4417 11.6159 23.2265 11.22 23.1075C10.8242 22.9886 10.405 22.9697 10 23.0524V12.9524C10.405 13.0352 10.8242 13.0163 11.22 12.8973C11.6159 12.7784 11.9761 12.5631 12.2684 12.2708C12.5607 11.9786 12.7759 11.6184 12.8949 11.2225C13.0138 10.8266 13.0328 10.4074 12.95 10.0024H23.05C22.9672 10.4074 22.9862 10.8266 23.1051 11.2225C23.2241 11.6184 23.4393 11.9786 23.7316 12.2708C24.0239 12.5631 24.3841 12.7784 24.78 12.8973C25.1758 13.0163 25.595 13.0352 26 12.9524Z"
                    stroke="#945FAF"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M13 10.5025C13 11.1656 12.7366 11.8014 12.2678 12.2703C11.7989 12.7391 11.163 13.0025 10.5 13.0025C9.83696 13.0025 9.20108 12.7391 8.73223 12.2703C8.26339 11.8014 8 11.1656 8 10.5025C7.99938 9.88254 8.22914 9.28445 8.64469 8.82435C9.06023 8.36425 9.63192 8.07497 10.2488 8.01266C10.8656 7.95036 11.4836 8.11947 11.9827 8.48717C12.4819 8.85488 12.8266 9.39494 12.95 10.0025C12.98 10.1625 13 10.3325 13 10.5025ZM28 10.5025C28 11.1656 27.7366 11.8014 27.2678 12.2703C26.7989 12.7391 26.163 13.0025 25.5 13.0025C24.837 13.0025 24.2011 12.7391 23.7322 12.2703C23.2634 11.8014 23 11.1656 23 10.5025C23 9.83948 23.2634 9.20359 23.7322 8.73475C24.2011 8.26591 24.837 8.00252 25.5 8.00252C26.163 8.00252 26.7989 8.26591 27.2678 8.73475C27.7366 9.20359 28 9.83948 28 10.5025V10.5025ZM13 25.5025C13 26.1656 12.7366 26.8014 12.2678 27.2703C11.7989 27.7391 11.163 28.0025 10.5 28.0025C9.83696 28.0025 9.20108 27.7391 8.73223 27.2703C8.26339 26.8014 8 26.1656 8 25.5025C8 24.8395 8.26339 24.2036 8.73223 23.7348C9.20108 23.2659 9.83696 23.0025 10.5 23.0025C11.163 23.0025 11.7989 23.2659 12.2678 23.7348C12.7366 24.2036 13 24.8395 13 25.5025V25.5025ZM28 25.5025C28.0006 26.1225 27.7709 26.7206 27.3553 27.1807C26.9398 27.6408 26.3681 27.9301 25.7512 27.9924C25.1344 28.0547 24.5164 27.8856 24.0173 27.5179C23.5181 27.1502 23.1734 26.6101 23.05 26.0025C22.9805 25.6602 22.9835 25.3072 23.0588 24.9661C23.1341 24.625 23.28 24.3035 23.4872 24.0223C23.6943 23.7411 23.9581 23.5064 24.2615 23.3334C24.5649 23.1603 24.9012 23.0528 25.2488 23.0177C25.5963 22.9826 25.9473 23.0207 26.2792 23.1296C26.6111 23.2384 26.9164 23.4156 27.1757 23.6497C27.4349 23.8838 27.6421 24.1697 27.7841 24.4888C27.9261 24.8079 27.9997 25.1532 28 25.5025V25.5025Z"
                    stroke="#945FAF"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>

                <input
                  style="margin-left :-2.4rem; padding-left:3rem;"
                  type="number"
                  min="1"
                  max="120"
                  step="1"
                  class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="grid-state"
                  bind:value={_size}
                  on:change={onChangeSize}
                />
              </div>
            </div>
          </div>

          {#if typeSelected == "Text"}
            <div class="fontFamilyInput mr-2 flex-col items-center">
              <div style="margin: 5px;">
                <p class="text-white">{$_("workspace.famillePolice")}</p>
                <div
                  class="relative w-60 md:w-60"
                  style="display:flex;flex-direction:row;"
                >
                  <svg
                    class="z-10"
                    width="48"
                    height="46"
                    viewBox="0 0 36 36"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="40" height="38" rx="5" fill="#F3EAF8" />
                    <path
                      d="M8 12.89V11.07C8 9.92 8.93 9 10.07 9H24.59C25.74 9 26.66 9.93 26.66 11.07V12.89M17.33 26.44V9.83M13.39 26.44H21.27"
                      stroke="#945FAF"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>

                  <select
                    style="margin-left :-2.4rem; padding-left:3rem;"
                    bind:value={_fontFamily}
                    on:change={onChangeFont}
                    class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  >
                    {#each Families as family}
                      <option value={family}>{family}</option>
                    {/each}
                  </select>
                </div>
              </div>
            </div>
            <div class="policeTypeInput mr-2 flex-col items-center">
              <div style="margin: 5px;">
                <p class="text-white">{$_("workspace.typePolice")}</p>
                <div
                  class="relative w-60 md:w-60"
                  style="display:flex;flex-direction:row;"
                >
                  <svg
                    class="z-10"
                    width="48"
                    height="46"
                    viewBox="0 0 36 36"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="40" height="38" rx="5" fill="#F3EAF8" />
                    <path
                      d="M15 28H21C26 28 28 26 28 21V15C28 10 26 8 21 8H15C10 8 8 10 8 15V21C8 26 10 28 15 28Z"
                      stroke="#945FAF"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M13 14.8899C16.15 13.3199 19.85 13.3199 23 14.8899M18 22.2999V13.9299"
                      stroke="#945FAF"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>

                  <select
                    style="margin-left :-2.4rem; padding-left:3rem;"
                    class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    bind:value={_currentVariant}
                    on:change={onChangeVariant}
                  >
                    {#each variants as variant}
                      <option value={variant}> {variant} </option>
                    {/each}
                  </select>
                </div>
              </div>
            </div>
          {/if}
          {#if typeSelected == "Text"}
            <div class="mr-2 flex-col items-center">
              <div style="margin: 5px;">
                <p class="text-white">Type de caractere</p>
                <div style="display:flex;flex-direction:row;">
                  <svg
                    class="z-10"
                    width="48"
                    height="46"
                    viewBox="0 0 36 36"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="40" height="38" rx="5" fill="#F3EAF8" />
                    <path
                      d="M8 12.89V11.07C8 9.92 8.93 9 10.07 9H24.59C25.74 9 26.66 9.93 26.66 11.07V12.89M17.33 26.44V9.83M13.39 26.44H21.27"
                      stroke="#945FAF"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  <select
                    class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="grid-state"
                    style="margin-left :-2.4rem; padding-left:3rem;"
                    bind:value={langue}
                    on:change={() => {
                      dispatch("update", {
                        langue: langue,
                      });
                    }}
                  >
                    {#each langues as lg}
                      <option value={lg}>{lg}</option>
                    {/each}
                  </select>
                </div>
              </div>
            </div>
          {/if}
          {#if typeSelected == "Text"}
            <div class="mr-2 flex-col items-center">
              <div style="margin: 5px;">
                <p class="text-white">Options</p>
                <div style="display:flex;flex-direction:row;">
                  <svg
                    class="z-10"
                    width="48"
                    height="46"
                    viewBox="0 0 36 36"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="40" height="38" rx="5" fill="#F3EAF8" />
                    <path
                      d="M23 19.4V22.4C23 26.4 21.4 28 17.4 28H13.6C9.6 28 8 26.4 8 22.4V18.6C8 14.6 9.6 13 13.6 13H16.6"
                      stroke="#945FAF"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M17.6 8H21.6M13 11C13 9.34 14.34 8 16 8H18.62M28 14V20.19C28 21.74 26.74 23 25.19 23M23 19.4H19.8C17.4 19.4 16.6 18.6 16.6 16.2V13L23 19.4ZM28 14H25C22.75 14 22 13.25 22 11V8L28 14Z"
                      stroke="#945FAF"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>

                  <select
                    style="margin-left :-2.4rem; padding-left:3rem;"
                    class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="grid-state"
                    bind:value={TextFormat}
                    on:change={() => {
                      switch (TextFormat) {
                        case "Basique":
                          dispatch("update", {
                            TextFormat: TextFormat,
                            FormatCode: 0,
                          });
                          break;
                        case "Adaptation Taille Police":
                          dispatch("update", {
                            TextFormat: TextFormat,
                            FormatCode: 1,
                          });
                          break;
                        case "Retour a la ligne automatique":
                          dispatch("update", {
                            TextFormat: TextFormat,
                            FormatCode: 2,
                          });
                          break;
                        case "Selection":
                          dispatch("update", {
                            TextFormat: TextFormat,
                          });
                          break;
                      }
                    }}
                  >
                    {#each TextFormats as format}
                      <option value={format}>{format}</option>
                    {/each}
                  </select>
                </div>
              </div>
            </div>
          {/if}
          {#if TextFormat == "Selection" && livePrint == "Oui"}
            <AddOptions
              {options}
              on:update={(e) => {
                dispatch("update", {
                  options: e.detail.options,
                });
              }}
            />
          {/if}
          {#if typeSelected == "Date"}
            <div class="mr-2 flex-col items-center">
              <div style="margin: 5px;">
                <p class="text-white">Format</p>
                <div style="display:flex;flex-direction:row;">
                  <svg
                    class="z-10"
                    width="48"
                    height="46"
                    viewBox="0 0 36 36"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="40" height="38" rx="5" fill="#F3EAF8" />
                    <path
                      d="M13.9952 8V10.9971M21.9876 8V10.9971M9.49951 15.0832H26.4833M25.1945 21.7568L21.6579 25.2935C21.518 25.4333 21.3881 25.6931 21.3582 25.8829L21.1684 27.2316C21.0984 27.7211 21.4381 28.0608 21.9276 27.9909L23.2763 27.8011C23.4662 27.7711 23.7359 27.6412 23.8658 27.5013L27.4024 23.9647C28.0118 23.3553 28.3015 22.646 27.4024 21.7468C26.5132 20.8577 25.8039 21.1474 25.1945 21.7568Z"
                      stroke="#945FAF"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M24.685 22.2663C24.9847 23.3453 25.8239 24.1845 26.9029 24.4842M17.9914 27.9809H13.9952C10.4986 27.9809 9 25.9828 9 22.9856V14.4938C9 11.4966 10.4986 9.49854 13.9952 9.49854H21.9876C25.4842 9.49854 26.9828 11.4966 26.9828 14.4938V17.9904"
                      stroke="#945FAF"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M17.9864 19.689H17.9964M14.2889 19.689H14.2989M14.2889 22.6861H14.2989"
                      stroke="#945FAF"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>

                  <select
                    style="margin-left :-2.4rem; padding-left:3rem;"
                    class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="grid-state"
                    bind:value={dateFormat}
                    on:change={() =>
                      dispatch("update", {
                        dateFormat: dateFormat,
                      })}
                  >
                    {#each dateFormats as format}
                      <option value={format}>{format}</option>
                    {/each}
                  </select>
                </div>
              </div>
            </div>
          {/if}

          {#if typeSelected == "Numero"}
            <div class="mr-2 flex-col items-center">
              <div style="margin: 5px;">
                <p class="text-white">
                  {$_("workspace.Modéle du numéro téléphone")}
                </p>
                <div style="display:flex;flex-direction:row;">
                  <svg
                    class="z-10"
                    width="48"
                    height="46"
                    viewBox="0 0 36 36"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="40" height="38" rx="5" fill="#F3EAF8" />
                    <path
                      d="M27.97 24.33C27.97 24.69 27.89 25.06 27.72 25.42C27.55 25.78 27.33 26.12 27.04 26.44C26.55 26.98 26.01 27.37 25.4 27.62C24.8 27.87 24.15 28 23.45 28C22.43 28 21.34 27.76 20.19 27.27C19.04 26.78 17.89 26.12 16.75 25.29C15.5881 24.4401 14.4917 23.5041 13.47 22.49C12.4588 21.472 11.5261 20.3789 10.68 19.22C9.86 18.08 9.2 16.94 8.72 15.81C8.24 14.67 8 13.58 8 12.54C8 11.86 8.12 11.21 8.36 10.61C8.6 10 8.98 9.44 9.51 8.94C10.15 8.31 10.85 8 11.59 8C11.87 8 12.15 8.06 12.4 8.18C12.66 8.3 12.89 8.48 13.07 8.74L15.39 12.01C15.57 12.26 15.7 12.49 15.79 12.71C15.88 12.92 15.93 13.13 15.93 13.32C15.93 13.56 15.86 13.8 15.72 14.03C15.59 14.26 15.4 14.5 15.16 14.74L14.4 15.53C14.29 15.64 14.24 15.77 14.24 15.93C14.24 16.01 14.25 16.08 14.27 16.16C14.3 16.24 14.33 16.3 14.35 16.36C14.53 16.69 14.84 17.12 15.28 17.64C15.73 18.16 16.21 18.69 16.73 19.22C17.27 19.75 17.79 20.24 18.32 20.69C18.84 21.13 19.27 21.43 19.61 21.61C19.66 21.63 19.72 21.66 19.79 21.69C19.87 21.72 19.95 21.73 20.04 21.73C20.21 21.73 20.34 21.67 20.45 21.56L21.21 20.81C21.46 20.56 21.7 20.37 21.93 20.25C22.16 20.11 22.39 20.04 22.64 20.04C22.83 20.04 23.03 20.08 23.25 20.17C23.47 20.26 23.7 20.39 23.95 20.56L27.26 22.91C27.52 23.09 27.7 23.3 27.81 23.55C27.91 23.8 27.97 24.05 27.97 24.33V24.33Z"
                      stroke="#945FAF"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                    />
                    <path
                      d="M24.5 15C24.5 14.4 24.03 13.48 23.33 12.73C22.69 12.04 21.84 11.5 21 11.5M28 15C28 11.13 24.87 8 21 8"
                      stroke="#945FAF"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>

                  <select
                    style="margin-left :-2.4rem; padding-left:3rem;"
                    class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="grid-state"
                    bind:value={numFormat}
                    on:change={onChangeNumFormat}
                  >
                    {#each numFormats as format}
                      <option value={format}>{format}</option>
                    {/each}
                  </select>
                </div>
              </div>
            </div>
          {/if}

          {#if typeSelected == "Check"}
            <div class="mr-2 flex-col items-center">
              <div style="margin: 5px;">
                <p class="text-white">Type</p>
                <div style="display:flex;flex-direction:row;">
                  <svg
                    class="z-10"
                    width="48"
                    height="46"
                    viewBox="0 0 36 36"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="40" height="38" rx="5" fill="#F3EAF8" />
                    <path
                      d="M18 28C23.5 28 28 23.5 28 18C28 12.5 23.5 8 18 8C12.5 8 8 12.5 8 18C8 23.5 12.5 28 18 28Z"
                      stroke="#945FAF"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M13.75 17.9999L16.58 20.8299L22.25 15.1699"
                      stroke="#945FAF"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>

                  <select
                    class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="grid-state"
                    style="margin-left :-2.4rem; padding-left:3rem;"
                    bind:value={checkFormat}
                    on:change={() =>
                      dispatch("update", {
                        checkFormat: checkFormat,
                      })}
                  >
                    {#each checktypes as type}
                      <option value={type}>{type}</option>
                    {/each}
                  </select>
                </div>
              </div>
            </div>
          {/if}
          <div class="allowedNumber mr-2 flex-col items-center">
            <div style="margin: 5px;">
              <p class="text-white">{$_("workspace.nombreCaracteres")}</p>
              <div style="display:flex;flex-direction:row;">
                <svg
                  class="z-10"
                  width="48"
                  height="46"
                  viewBox="0 0 36 36"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width="40" height="38" rx="5" fill="#F3EAF8" />
                  <path
                    d="M20.01 9L22.45 11.34L14.5 11.32C10.93 11.32 8 14.25 8 17.84C8 19.63 8.73 21.26 9.91 22.44M16.01 27L13.57 24.66L21.52 24.68C25.09 24.68 28.02 21.75 28.02 18.16C28.02 16.37 27.29 14.74 26.11 13.56"
                    stroke="#945FAF"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M18.26 20.6701V15.3301L16.76 17.0001"
                    stroke="#945FAF"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>

                <input
                  type="number"
                  style="margin-left :-2.4rem; padding-left:3rem;"
                  min="0"
                  step="1"
                  class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="grid-state"
                  bind:value={charactersNumber}
                  on:change={onChangeCharactersNumber}
                />
              </div>
            </div>
          </div>
          {#if allObjects}
            <div class="mr-2 flex-col items-center">
              <div style="margin: 5px;">
                <p class="text-white">Page</p>
                <div style="display:flex;flex-direction:row;">
                  <svg
                    class="z-10"
                    width="48"
                    height="46"
                    viewBox="0 0 36 36"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="40" height="38" rx="5" fill="#F3EAF8" />
                    <path
                      d="M20.01 9L22.45 11.34L14.5 11.32C10.93 11.32 8 14.25 8 17.84C8 19.63 8.73 21.26 9.91 22.44M16.01 27L13.57 24.66L21.52 24.68C25.09 24.68 28.02 21.75 28.02 18.16C28.02 16.37 27.29 14.74 26.11 13.56"
                      stroke="#945FAF"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M18.26 20.6701V15.3301L16.76 17.0001"
                      stroke="#945FAF"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  <input
                    type="number"
                    style="margin-left :-2.4rem; padding-left:3rem;"
                    max={allObjects.length}
                    step="1"
                    class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="grid-state"
                    value={pIndex + 1}
                    on:change={moveToPage}
                  />
                </div>
              </div>
            </div>
          {/if}
          <div class="mr-2 flex-col items-center">
            <div style="margin: 5px;">
              <p class="text-white">{$_("workspace.Visibilité")}</p>
              <div style="display:flex;flex-direction:row;">
                <Visibility />

                <select
                  style="margin-left :-2.4rem; padding-left:3rem;"
                  bind:value={visibility}
                  on:change={onChangeVisibility}
                  class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="grid-state"
                >
                  <option value="Oui">{$_("workspace.oui")}</option>
                  <option value="Non">{$_("workspace.non")}</option>
                </select>
              </div>
            </div>
          </div>

          {#if LivePrintTypes.includes(typeSelected)}
            <div class="LivePrintInput mr-2 flex-col items-center">
              <div style="margin: 5px;">
                <p class="text-white">Live Print</p>
                <div style="display:flex;flex-direction:row;">
                  <LivePrint />

                  <select
                    style="margin-left :-2.4rem; padding-left:3rem;"
                    bind:value={livePrint}
                    on:change={onChangeLivePrint}
                    class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="grid-state"
                  >
                    <option value="Oui">{$_("workspace.oui")}</option>
                    <option value="Non">{$_("workspace.non")}</option>
                  </select>
                </div>
              </div>
            </div>
          {/if}

          <div style="display: flex;flex-direction:column; margin:5px;">
            <p class="text-white">{$_("workspace.couleurText")}</p>
            <div style="display:flex;flex-direction:row;">
              <svg
                class="z-10"
                width="48"
                height="46"
                viewBox="0 0 36 36"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="40" height="38" rx="5" fill="#F3EAF8" />
                <path
                  d="M15.3392 8L16.6758 9.33666M9.07481 17.9451L24.1571 17.2868M10.0025 28H22.9701M14.0025 24.01L10.0025 20.01C8.66584 18.6733 8.66584 17.3466 10.0025 16.01L16.6658 9.34663L23.9975 16.6783C24.3666 17.0474 24.3666 17.6459 23.9975 18.015L17.9925 24.02C16.6758 25.3367 15.3392 25.3367 14.0025 24.01V24.01Z"
                  stroke="#945FAF"
                  stroke-width="1.5"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M25.8129 21.0171C25.8129 21.0171 23.9675 23.0221 23.9675 24.249C23.9675 25.2665 24.7955 26.0944 25.8129 26.0944C26.8304 26.0944 27.6583 25.2665 27.6583 24.249C27.6583 23.0221 25.8129 21.0171 25.8129 21.0171V21.0171Z"
                  stroke="#945FAF"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>

              <input
                disabled
                style="margin-left :-3rem; width:91%;border-radius:0.5rem; padding-left:3.2rem;"
                class=" w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 rounded focus:outline-none focus:bg-white focus:border-gray-500"
                bind:value={hex}
              />
              {#if color}
                <input
                  on:change={(e) => {
                    let red = parseInt(e.target.value.substring(1, 3), 16);
                    let green = parseInt(e.target.value.substring(3, 5), 16);
                    let blue = parseInt(e.target.value.substring(5, 7), 16);
                    colorCallback({ r: red, g: green, b: blue });
                    hex = rgbToHex(red, green, blue);
                  }}
                  style="width:17%;height:3.1rem;z-index:10; margin-left:-2rem ;"
                  type="color"
                  id="favcolor"
                  name="favcolor"
                  value={rgbToHex(color.r, color.g, color.b)}
                />
              {:else}
                <input
                  on:change={(e) => {
                    const red = parseInt(e.target.value.substring(1, 3), 16);
                    const green = parseInt(e.target.value.substring(3, 5), 16);
                    const blue = parseInt(e.target.value.substring(5, 7), 16);
                    colorCallback({ r: red, g: green, b: blue });
                    hex = rgbToHex(red, green, blue);
                  }}
                  style="width:17%;height:3.1rem;z-index:10; margin-left:-2rem ;"
                  type="color"
                  id="favcolor"
                  name="favcolor"
                  value={rgbToHex(0, 0, 0)}
                />
              {/if}
            </div>
          </div>
        </div>
      </div>
    </div>
  </Toolbar>
{/if}

<div
  class="absolute left-0 top-0 select-none"
  style="width: {width + dw}px; height: {height +
    dh}px; transform: translate({x + dx}px,
  {y + dy}px);"
  use:tapout
  on:tapout={onBlur}
>
  <div
    use:pannable
    on:panstart={handlePanStart}
    on:panmove={handlePanMove}
    on:panend={handlePanEnd}
    class=" w-full h-full cursor-grab"
    class:cursor-grabbing={operation === "move"}
    class:operation
    on:dblclick={() => {
      selected = true;
      operation = "tool";
      dispatch("mode", "EDIT");
    }}
  >
    <div
      data-direction="left"
      class="resize-border h-full w-1 left-0 top-0 border-l cursor-ew-resize"
    />
    <div
      data-direction="top"
      class="resize-border w-full h-1 left-0 top-0 border-t cursor-ns-resize"
    />
    <div
      data-direction="bottom"
      class="resize-border w-full h-1 left-0 bottom-0 border-b cursor-ns-resize"
    />
    <div
      data-direction="right"
      class="resize-border h-full w-1 right-0 top-0 border-r cursor-ew-resize"
    />
    {#if operation}
      <div
        data-direction="left-top"
        class="dots left-0 top-0 cursor-nwse-resize transform
    -translate-x-1/2 -translate-y-1/2"
      />
      <div
        data-direction="right-top"
        class="dots right-0 top-0 cursor-nesw-resize transform
    translate-x-1/2 -translate-y-1/2"
      />
      <div
        data-direction="left-bottom"
        class="dots left-0 bottom-0 cursor-nesw-resize transform
    -translate-x-1/2 translate-y-1/2"
      />
      <div
        data-direction="right-bottom"
        class="dots right-0 bottom-0 cursor-nwse-resize transform
    translate-x-1/2 translate-y-1/2"
      />
      <div
        on:click={onDelete}
        class="absolute left-0 top-0 right-0 w-6 h-6 m-auto rounded-full bg-white
    cursor-pointer transform -translate-y-1/2 md:scale-25"
      >
        <img class="w-full h-full" src="/delete.svg" alt="delete object" />
      </div>
    {/if}

    {#if color}
      <div
        bind:this={editable}
        on:keydown={onKeydown}
        on:focus={onFocus}
        contenteditable={editMode == "EDIT" ? "true" : "false"}
        class="outline-none"
        spellcheck="false"
        style="font-size: {_size}px; font-family: '{_fontFamily}', serif;
      white-space:nowrap; overflow: hidden; white-space: nowrap;
      color: {rgbToHex(color.r, color.g, color.b)};
  line-height: {_lineHeight}; {editMode == 'MOVE'
          ? 'pointer-events:none;-webkit-user-select: text;  user-select: none;-webkit-user-select: none;-moz-user-select: none;-ms-user-select: none;'
          : ''}"
      />
    {:else}
      <div
        bind:this={editable}
        on:keydown={onKeydown}
        on:focus={onFocus}
        contenteditable={editMode == "EDIT" ? "true" : "false"}
        class="outline-none"
        spellcheck="false"
        style="font-size: {_size}px; font-family: '{_fontFamily}', serif;
    white-space:nowrap; overflow: hidden; white-space: nowrap;
line-height: {_lineHeight}; {editMode == 'MOVE'
          ? 'pointer-events:none;-webkit-user-select: text;  user-select: none;-webkit-user-select: none;-moz-user-select: none;-ms-user-select: none;'
          : ''}"
      />
    {/if}
  </div>
</div>

<style>
  .operation {
    background-color: rgba(0, 0, 0, 0.3);
  }
  .resize-border {
    @apply absolute border-dashed border-gray-600;
  }
  .ruler-x {
    height: var(--ruler2-h);
    inset-block-start: 0;
    inset-inline-start: calc(var(--ruler-unit) * var(--ruler2-space));
    opacity: var(--ruler-x);
    width: 600px;
  }
  .ruler-y {
    flex-direction: column;
    height: 820px;
    inset-block-start: calc(var(--ruler-unit) * var(--ruler2-space));
    inset-inline-start: 0;
    opacity: var(--ruler-y);
    width: var(--ruler2-h);
  }
  .ruler_container {
    --ruler-num-c: #888;
    --ruler-num-fz: 10px;
    --ruler-num-pi: 0.75ch;
    --ruler-unit: 1px;
    --ruler-x: 1;
    --ruler-y: 1;

    --ruler1-bdw: 1px;
    --ruler1-c: #bbb;
    --ruler1-h: 8px;
    --ruler1-space: 5;

    --ruler2-bdw: 1px;
    --ruler2-c: #bbb;
    --ruler2-h: 20px;
    --ruler2-space: 50;

    background-attachment: fixed;
    background-image: linear-gradient(
        90deg,
        var(--ruler1-c) 0 var(--ruler1-bdw),
        transparent 0
      ),
      linear-gradient(90deg, var(--ruler2-c) 0 var(--ruler2-bdw), transparent 0),
      linear-gradient(0deg, var(--ruler1-c) 0 var(--ruler1-bdw), transparent 0),
      linear-gradient(0deg, var(--ruler2-c) 0 var(--ruler2-bdw), transparent 0);
    background-position: 0 0;
    background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
    background-size: calc(
          var(--ruler-unit) * var(--ruler1-space) * var(--ruler-x)
        )
        var(--ruler1-h),
      calc(var(--ruler-unit) * var(--ruler2-space) * var(--ruler-x))
        var(--ruler2-h),
      var(--ruler1-h)
        calc(var(--ruler-unit) * var(--ruler1-space) * var(--ruler-y)),
      var(--ruler2-h)
        calc(var(--ruler-unit) * var(--ruler2-space) * var(--ruler-y));
  }
  .dots {
    position: absolute;
    width: 0.2rem;
    height: 0.2rem;
    --bg-opacity: 1;
    background-color: #90cdf4;
    background-color: rgba(144, 205, 244, var(--bg-opacity));
    border-radius: 9999px;
  }
  .sidebar {
    -webkit-animation: slide-in-left 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94)
      both;
    animation: slide-in-left 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  }
  ::-webkit-scrollbar {
    width: 12px; /* Width of the scrollbar */
    background-color: #624771; /* Background color of the scrollbar */
  }
  ::-webkit-scrollbar-thumb {
    background-color: #945faf; /* Color of the thumb */
  }
  @-webkit-keyframes slide-in-left {
    0% {
      -webkit-transform: translateX(-1000px);
      transform: translateX(-1000px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
      opacity: 1;
    }
  }
  @keyframes slide-in-left {
    0% {
      -webkit-transform: translateX(-1000px);
      transform: translateX(-1000px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
      opacity: 1;
    }
  }
  input[type="color"] {
    cursor: pointer;
    -webkit-appearance: none;
    border: none;
    width: 50%;
    height: 30px;
    border-radius: 5px;
  }
  input[type="color"]::-webkit-color-swatch-wrapper {
    padding: 0;
    border-radius: 5px;
  }
  input[type="color"]::-webkit-color-swatch {
    border: none;
    border-radius: 5px;
  }
</style>
