<script>
  import { onMount } from "svelte";
  import Tailwind from "../../Tailwind.svelte";
  import PDFPage from "../../components/PDFPage.svelte";
  import { openModal } from "svelte-modals";
  import SectionModal from "../../components/sections/SectionModal.svelte";
  import Header from "../../components/Header.svelte";
  import { fetchFont } from "../../utils/prepareAssets.js";
  import { readAsPDF } from "../../utils/asyncReader.js";
  import { ggID } from "../../utils/helper.js";
  import axios from "axios";
  import LivePrintField from "./LivePrintField.svelte";
  import { navigate } from "svelte-routing";
  import Swal from "sweetalert2";
  import BackToTop from "../../components/BackToTop.svelte";
  import BackToPreviousPage from "../../components/BackToPreviousPage.svelte";
  import GotoNextPage from "../../components/GotoNextPage.svelte";
  import { onDestroy } from "svelte";
  import { _ } from "../../services/i18n";
  import LoadingPdf from "../../assets/svg/LoadingPdf.svelte";
  import LivePrintBtn from "../../components/LivePrintBtn.svelte";
  import PrintLoader from "../../components/PrintLoader.svelte";

  onDestroy(() => {
    let child = document.getElementById("portal");
    if (child) {
      document.body.removeChild(child);
    }
  });
  const genID = ggID();
  let children = [];
  let pdfFile;
  let pdfName = "";
  let pages = [];
  let pagesScale = [];
  let allObjects = [];
  let allSections = [];
  let SelectedSection = 0;
  let scale = 2;
  let currentFont = "Courier";
  let LivePrintTypes = ["Date", "Check", "BIC", "IBAN", "Text"];
  let selectedPageIndex = -1;
  let editMode = "EDIT";
  let Printing = false;
  let pdfpageHeight;
  let scroll;
  let position;
  let details;
  let id;
  let token;
  let APIKEY;
  const livePrintObject = {};
  onMount(async () => {
    const urlParams = new URLSearchParams(window.location.search);
    token = urlParams.get("lprintSk");

    await axios
      .get(`${process.env.BASE_URL}/liveprint/get_url/${token}`)
      .then((res) => {
        allObjects = JSON.parse(res.data.model.fieldsIds);
        APIKEY = res.data.model.apiKey;
        id = res.data.model.idModel;
        pdfName = res.data.model.modelName;
        if (res.data.live_print_data.file) {
          onUploadPDFBASE64(res.data.live_print_data.file);
        } else {
          onUploadPDF(res.data.model.fileUrl);
        }
      })
      .catch((e) => {
        setTimeout(() => navigate("/404"), 500);
      });
    sessionStorage.clear();
  });
  function updateIndex(index) {
    // console.log(index);
    selectedPageIndex = index;
  }
  function convertBase64ToBlob(base64) {
    // decode base64 string to binary data
    const binaryString = window.atob(base64);
    // convert binary to ArrayBuffer
    const arrayBuffer = new ArrayBuffer(binaryString.length);
    const uint8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < binaryString.length; i++) {
      uint8Array[i] = binaryString.charCodeAt(i);
    }
    // create blob from ArrayBuffer
    const blob = new Blob([uint8Array], { type: "application/pdf" });
    return blob;
  }

  async function readBase64PDF(base64) {
    const pdfBlob = convertBase64ToBlob(base64);
    return pdfBlob;
  }

  async function onUploadPDFBASE64(file) {
    const blob = readBase64PDF(file);
    blob.then((file) => {
      addPDF(file);
    });
    selectedPageIndex = 0;
  }
  async function onUploadPDF(url) {
    axios
      .get(`${process.env.BASE_URL}/${url.substring(1)}`, {
        responseType: "blob",
      })
      .then(async (res) => {
        await addPDF(res.data);
        selectedPageIndex = 0;
      });
  }

  async function addPDF(file) {
    try {
      const pdf = await readAsPDF(file);
      pdfFile = file;
      const numPages = pdf.numPages;
      pages = Array(numPages)
        .fill()
        .map((_, i) => pdf.getPage(i + 1));
      /* allObjects = pages.map(() => []); */
      pagesScale = Array(numPages).fill(1);
    } catch (e) {
      Swal.fire({
        icon: "error",
        // title: "Oops...",
        text: $_("workspace.Verifier l'intégrité  de votre fichier"),
      });
      throw e;
    }
  }

  function updateMode(mode) {
    editMode = mode;
  }

  function PrintPDF() {
    Printing = true;
    axios
      .post(
        `${process.env.BASE_URL}editique_pdf/`,
        {
          idModel: id,
          data: livePrintObject,
          live_print_token: token,
        },
        {
          headers: {
            apiKey: APIKEY,
          },
        }
      )
      .then((res) => {
        Printing = false;
        window.open(res.data.file_url);
        window.location = "/Success";
      })
      .catch((err) => {
        Printing = false;
        Swal.fire({
          icon: "error",
          // title: "Oops...",
          text:  $_('workspace.You have to fill at least one field'),
        });
      });
  }

  async function copyImage(obj) {
    const {
      id,
      width,
      height,
      size,
      lineHeight,
      line,
      charactersNumber,
      fieldType,
      x,
      y,
      visibility,
    } = obj.detail;
    try {
      fetchFont(currentFont);
      const object = {
        id: id,
        type: "image",
        width: width,
        height: height,
        x: x,
        y: y,
        size: size,
        lineHeight: lineHeight,
        fontFamily: currentFont,
        fieldType: fieldType,
        charactersNumber: charactersNumber,
        lines: line,
        visibility: visibility,
      };
      allObjects = allObjects.map((objects, pIndex) =>
        pIndex === selectedPageIndex ? [...objects, object] : objects
      );
    } catch (e) {
      console.log(`Fail to add image.`, e);
    }
  }
  function onAddTextField() {
    if (selectedPageIndex >= 0) {
      addTextField();
    }
  }
  function addTextField(text = "New Text Field") {
    const id = genID();
    fetchFont(currentFont);
    const object = {
      id,
      text,
      type: "text",
      size: 16,
      width: 0, // recalculate after editing
      lineHeight: 1,
      fontFamily: currentFont,
      x: 0,
      y: 0,
    };
    allObjects = allObjects.map((objects, pIndex) =>
      pIndex === selectedPageIndex ? [...objects, object] : objects
    );
  }
  function handleOpenImport() {
    openModal(SectionModal, {
      addSection: (Nom, Niveau) => {
        addSection(Nom, Niveau);
      },
      onOpenAnother: () => {
        handleOpenImport();
      },
    });
  }
  function insertAt(array, index, ...elementsArray) {
    array.splice(index, 0, ...elementsArray);
    return array;
  }
  function addSection(Nom, Niveau) {
    allSections = insertAt(allSections, Niveau - 1, { name: Nom, ids: [] });
  }

  function InsertIntoSection(obj) {
    let insertionSection = allSections[SelectedSection];
    insertionSection.ids = [...insertionSection.ids, obj];
    allSections[SelectedSection] = insertionSection;
  }

  function selectPage(index) {
    selectedPageIndex = index;
  }

  function updateObject(payload) {
    if (payload.key) {
      livePrintObject[payload.key] = payload.lines;
    }
  }

  function deleteFromSection(objectId) {
    let newSections = allSections;
    newSections.map((section) => {
      section.ids.map((element, index) => {
        if (element.id == objectId) {
          section.ids.splice(index, 1);
        }
      });
    });
    let newChildren = children;
    newChildren.map((child, index) => {
      if (child["$capture_state"]().id == objectId) {
        newChildren.splice(index, 1);
      }
    });
    allSections = newSections;
    children = newChildren;
  }

  function deleteObject(objectId) {
    allObjects = allObjects.map((objects, pIndex) =>
      pIndex == selectedPageIndex
        ? objects.filter((object) => object.id !== objectId)
        : objects
    );
    deleteFromSection(objectId);
  }

  function onMeasure(scale, i) {
    pagesScale[i] = scale;
  }
</script>

<svelte:window
  on:dragenter|preventDefault
  on:dragover|preventDefault
  on:drop|preventDefault={onUploadPDF}
  bind:scrollY={scroll}
/>
<Tailwind />
<Header livePrint={true} />

{#if pages.length}
  <div class="w-full">
    {#each pages as page, pIndex (page)}
      <div
        class="p-5 w-full flex flex-col items-center overflow-hidden"
        on:mousedown={() => selectPage(pIndex)}
        on:touchstart={() => selectPage(pIndex)}
      >
        <div
          class="relative shadow-lg {editMode === 'MOVE'
            ? 'cursor-move'
            : 'cursor-crosshair'}"
          class:shadow-outline={pIndex === selectedPageIndex}
        >
          <PDFPage
            on:measure={(e) => onMeasure(e.detail.scale, pIndex)}
            {page}
            {scale}
          />
          <div
            class="absolute top-0 left-0 transform origin-top-left"
            style="transform: scale({pagesScale[pIndex]}); touch-action: none;"
          >
            {#each allObjects[pIndex] as object, index (object.id)}
              {#if object.type === "image" && object.livePrint === "Oui" && LivePrintTypes.includes(object.fieldType)}
                <LivePrintField
                  on:update={(e) => updateObject(e.detail)}
                  bind:this={children[children.length]}
                  x={object.x * scale}
                  y={object.y * scale}
                  id={object.id}
                  width={object.width * scale}
                  size={object.size * scale}
                  lineHeight={object.lineHeight}
                  height={object.height * scale}
                  fontFamily={object.fontFamily}
                  pageScale={pagesScale[pIndex]}
                  typeSelected={object.fieldType}
                  charactersNumber={object.charactersNumber}
                  line={object.lines}
                  relation={object.relation}
                  visibility={object.visibility}
                  color={object.color}
                  checkFormat={object.checkFormat}
                  dateFormat={object.dateFormat}
                  TextFormat={object.TextFormat}
                  options={object.options ? object.options : []}
                  langue={object.langue}
                  _currentVariant={object.variant}
                  type={object.fieldType}
                  {editMode}
                  bind:details
                  bind:position
                  {allObjects}
                  {pIndex}
                  {pdfpageHeight}
                  {scroll}
                  bind:selectedPageIndex
                  on:mode={(e) => updateMode(e.detail)}
                />
              {/if}
            {/each}
          </div>
        </div>
      </div>
    {/each}
  </div>
  <div style="display: grid;place-items:center;margin:2vh" on:click={PrintPDF}>
    <LivePrintBtn />
  </div>
{:else}
  <div
    class="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden flex flex-col items-center justify-center"
  >
    <LoadingPdf />
  </div>
{/if}
<!-- </main> -->
<BackToTop {selectedPageIndex} on:update={(e) => updateIndex(e.detail)} />

{#if Printing}
  <PrintLoader />
{/if}
<BackToPreviousPage />
<GotoNextPage />

<!-- <Modals>
  <div slot="backdrop" class="backdrop" on:click={closeModal} />
</Modals> -->

<style>
  .navColor {
    background-color: #3b194d;
  }
  .sidebarr {
    background-color: #624771;
  }
  .linkContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    margin-bottom: 2rem;
    cursor: pointer;
    width: 100%;
  }
  .sidebarContainer {
    margin-top: 10rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-width: 100%;
  }
  @media only screen and (min-width: 1200px) and (max-width: 1590px) {
    .retourBtn {
      margin-left: 0;
      padding-right: 0;
      padding-left: 4px;
    }
    .retour {
      width: 28px;
    }
    .logoBtn {
      margin-right: 0.5rem;
      padding: 0;
    }
    .logo {
      width: 160px;
    }
    .outils {
      margin-right: 5px;
    }
    .outilsBars {
      display: flex;
      flex-direction: row;
      margin: 0.2rem;

      width: 25%;
    }
    .newChamp {
      width: 25px;
    }
    .move {
      width: 25px;
    }
    .edit {
      width: 25px;
    }
    .copyy {
      width: 25px;
      margin-right: 0.2rem;
    }
    .nameInput {
      width: 48% !important;
      border-radius: 0.2rem !important;
      padding: 0.3rem !important;
      margin: 0.2rem !important;
    }
    .saveBtn {
      padding-inline: 0.2rem;
      padding-block: 4px;
      font-size: 0.9rem;
      margin-right: 0.2rem;
      font-weight: 500;
      width: 15%;
    }
    .exportBtn {
      padding-inline: 0.4rem;
      padding-block: 4px;
      font-size: 0.9rem;
      margin-right: 0.2rem;
      font-weight: 500;
      width: 15%;
    }
    .importBtn {
      font-size: 0.9rem;
      font-weight: 500;
      width: 30%;
      text-align: center;
      margin-right: 0.3rem;
    }
    .pagesN {
      padding-left: 0.3rem;
      margin: 0;
      width: 12%;
    }

    .npage {
      width: 25%;
    }
  }
  .borderHighlight {
    border-color: #9ecaed;
    box-shadow: 0 0 10px #9ecaed;
  }

  svg {
    transition: transform 0.2s ease-in;
  }

  [aria-expanded="true"] svg {
    transform: rotate(-0.25turn);
  }
</style>
